import './App.css';
import { useState } from 'react';

function App() {
  const [answer, setAnswer] = useState('Yes');


  return (
    <div className="app">
      <div id="answer">{answer}</div>
    </div>
  );
}

export default App;
